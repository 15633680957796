import "./Hero.css";
import bridgeLogo from "../images/bridge.svg";

function Hero() {
    return (
        <div className="App">
            <div className="image-section"></div>

            <div className="content-top">
                <div className="about-bridge23">
                    <div className="bridge23-wrapper">
                        <div className="bridge23-logo">
                            <img src={bridgeLogo} />
                            <span className="mont bridge-23-head">Bridge23</span>
                        </div>
                        <span className="popp bridge23-title">
                            Developing products and games on multi-chain world
                        </span>
                        <span className="popp main-content" style={{ maxWidth: "760px" }}>
                            We continuously innovate to advance true ownership of digital
                            assets, borderless global economy and user-owned communities.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
