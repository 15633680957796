import omm from './images/omm.png';
import ice from './images/ice.png';
import craft from './images/craft.png';
import balanced from './images/balanced.png';
import ActiveProjects from "./components/ActiveProjects"
import Hero from "./components/Hero"

function App() {
  return (
    <div className="App">
      <Hero />

      <ActiveProjects />

      <div className="about-icon-wrap">
        <p>Technical consultants</p>
        <br />

        <div className="about-icon">
          <img src={ice} width="120px" class="logos-partners" onClick={() => { window.open("https://icenetwork.io/", "_blank") }} />
          <img src={omm} width="130px" class="logos-partners" onClick={() => window.open("https://omm.finance", "_blank")} />
          <img src={craft} width="60px" class="logos-partners" onClick={() => window.open("https://craft.network/", "_blank")} />
          <img src={balanced} width="90px" class="logos-partners" onClick={() => window.open("https://balanced.network/", "_blank")} />
        </div>
      </div>

      <div className='footer'>
        &copy; Bridge23
      </div>
    </div >
  );
}

export default App;
