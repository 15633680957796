import gbCollection from '../images/background.png';
import wlCollection from '../images/wl-background.png';
import { BsTelegram, BsTwitter, BsDiscord, BsMedium } from 'react-icons/bs';
import webLogo from '../images/web.svg';
import gbLogo from '../images/gb-logo.png';
import wlLogo from '../images/wl-logo.png';
import pgLogo from '../images/pg-logo.png';
import pgPicture from '../images/papergreets.jpg';

function ActiveProjects() {
    return (
        <div className="active-projects">
            <p className='popp__project'>Products</p>
            <div className='card'>
                <div>
                    <div className='head-div'>
                        <div className='head-inner-div'>
                            <img src={gbLogo} />
                            <div>
                                <span className="project-heading">GangstaBet</span>
                                <span className='sub-heading'>A Digital Collectible of the Future </span>
                            </div>
                        </div>
                        <br />

                        <div className='project-description'>
                            GangstaBet started as a digital collectible with 5555 Gangsters and Detectives with changeable names and stats. $GBET tokens are distributed daily to the NFTs, and burning them is the only way to change their properties. GangstaBet provides an immersive digital collectible experience where owners are an inherent part of the art.
                        </div> <br />

                        <p className='visit-website ›'><a href="https://gangstabet.io/">Visit website</a></p>

                    </div>
                    <div className='circle-collection-wrap'>
                        <div className='circle-collection'>
                            <div className='circle-clr'><a href="https://gangstabet.io" target="_blank"><img src={webLogo} height="20" /></a></div>
                            <div className='circle-clr'><a href="https://t.me/gangstabet_icon" target="_blank"><BsTelegram size={20} /></a></div>
                            <div className='circle-clr'><a href="https://discord.com/invite/9jkyefW9kU" target="_blank"><BsDiscord size={20} /></a></div>
                            <div className='circle-clr'><a href="https://twitter.com/_GangstaBet" target="_blank"><BsTwitter size={20} /></a></div>
                            <div className='circle-clr'><a href="https://gangstabet.medium.com/" target="_blank"><BsMedium size={20} /></a></div>
                        </div>
                        <img src={gbCollection} width="100%" />
                    </div>
                </div>
            </div>

            <div className='card'>
                <div>
                    <div className='head-div'>
                        <div className='head-inner-div'>
                            <img src={wlLogo} style={{ height: "31px" }} />
                            <div>
                                <span className="project-heading">Wonder Game</span>
                                <span className='sub-heading'>Collaborative DeFi Game on a Cross-Chain Adventure
                                </span>
                            </div>
                        </div>
                        <br />

                        <div className='project-description'>
                            Wonder Game is a collaborative DeFi game that takes players on a cross-chain adventure to different blockchains with each new chapter. The first chapter encourages the player community to work together while strategically competing with one another to explore Wonder Game and find magical SHROOMs, which allows them to create and collect in-game items - boosting creativity and social interaction, all while bringing different ecosystems together.
                        </div> <br />

                        <p className='visit-website ›'><a href="https://wonder.game/">Visit website</a></p>

                    </div>
                    <div className='circle-collection-wrap'>
                        <div className='circle-collection'>
                            <div className='circle-clr'><a href="https://wonder.game/" target="_blank"><img src={webLogo} height="20" /></a></div>
                            <div className='circle-clr'><a href="https://t.me/+Sd8KnVl_8O82Nzkx" target="_blank"><BsTelegram size={20} /></a></div>
                            <div className='circle-clr'><a href="https://discord.gg/wonderdotgame" target="_blank"><BsDiscord size={20} /></a></div>
                            <div className='circle-clr'><a href="https://twitter.com/wonderdotgame" target="_blank"><BsTwitter size={20} /></a></div>
                            <div className='circle-clr'><a href="https://wonderdotgame.medium.com/" target="_blank"><BsMedium size={20} /></a></div>
                        </div>
                        <img src={wlCollection} width="100%" />
                    </div>
                </div>
            </div>


            <div className='card'>
                <div>
                    <div className='head-div'>
                        <div className='head-inner-div'>
                            <img src={pgLogo} style={{ height: "31px" }} />
                            <div>
                                <span className="project-heading">PaperGreets</span>
                                <span className='sub-heading'>Explore and Send World's First Cross-Chain NFT Postcards </span>
                            </div>
                        </div>
                        <br />

                        <div className='project-description'>
                            Non-Fungible Postcards or NFPs are digital representations of physical postcards. As avid collectors of physical postcards and stamps, we were inspired to create the same experience through NFPs. Like postcards, NFPs are collectible digital mementos with unique stories, art & meanings, sent to you by friends and family.
                        </div> <br />

                        <p className='visit-website ›'><a href="https://www.papergreets.com/">Visit website</a></p>

                    </div>
                    <div className='circle-collection-wrap'>
                        <div className='circle-collection'>
                            <div className='circle-clr'><a href="https://www.papergreets.com/" target="_blank"><img src={webLogo} height="20" /></a></div>
                            <div className='circle-clr'><a href="https://t.me/papergreets" target="_blank"><BsTelegram size={20} /></a></div>
                            <div className='circle-clr'><a href="https://discord.gg/afmRfZpZtK" target="_blank"><BsDiscord size={20} /></a></div>
                            <div className='circle-clr'><a href="https://twitter.com/papergreetsnfp" target="_blank"><BsTwitter size={20} /></a></div>
                            <div className='circle-clr'><a href="https://medium.com/@papergreetsnfp" target="_blank"><BsMedium size={20} /></a></div>
                        </div>
                        <img src={pgPicture} width="100%" />
                    </div>
                </div>
            </div>
            <br />

        </div>
    );
}

export default ActiveProjects;
